import React from 'react';
import { ShimmerCircularImage, ShimmerText, ShimmerTitle } from 'react-shimmer-effects';

const LoaderProfile = () => {
    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem',
        marginBottom: '10px'
    };

    const profileDetailsStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    };

    const titleStyle = {
        margin: 0,
        lineHeight: 1,
    };

    const textStyle = {
        margin: 0,
        lineHeight: 1,
    };

    return (
        <div className="user-chat-profile" style={containerStyle}>
            <div className="profile-picture-container me-3">
                <ShimmerCircularImage size={50} />
            </div>
            <div className="profile-details flex-grow-1" style={profileDetailsStyle}>
                <h6 className="mb-1" style={titleStyle}>
                    <ShimmerTitle line={1} gap={0} variant="primary" />
                </h6>
                <small className="text-muted last-message" style={textStyle}>
                    <ShimmerText line={1} gap={0} />
                </small>
            </div>
        </div>
    );
};

export default LoaderProfile;
