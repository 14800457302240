import React, { useContext, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import Cookies from 'js-cookie';
import { PlayerProfileContext } from "../../../context/PlayerProfileContext";
import { getCroppedImg } from "./cropUtils"; // Updated utility to return a Blob
import { ToastContainer, toast } from 'react-toastify';
import axiosinstance from "../../../axios/axiosInstance";
const Profile = () => {
    const [tempPlayerProfile, setTempProfile] = useState({
        profilepic: ""
    });
    const { playerProfileData, setPlayerProfileData } = useContext(PlayerProfileContext);
    const fileInputRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [cropModal, setCropModal] = useState(false); // Show cropper modal
    const [imageSrc, setImageSrc] = useState(null); // Original image to crop
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result); // Set image for cropping
                setCropModal(true); // Open cropper modal
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const saveCroppedImage = async () => {
        try {
            const { blob, base64 } = await getCroppedImg(imageSrc, croppedAreaPixels);
            const croppedImageFile = new File([blob], "profile.jpg", { type: "image/jpeg" });
            setPlayerProfileData((prevData) => ({
                ...prevData,
                profilePicture: croppedImageFile, // Save the File object
            }));
            setTempProfile((prevData) => ({
                ...prevData,
                profilepic: base64
            }))
            const formData = new FormData();
            formData.append('user_id', Cookies.get('user_id'));
            formData.append('profilePicture', croppedImageFile);
            axiosinstance.post("/user/upload-profile-picture", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: Cookies.get('uid'),
                }
            }).then((response) => {
                if (response.status == 200) {
                    toast('Updated Profile Pic', {
                        className: 'custom-success-toast'
                    })
                }
                else if (response.status == 500) {
                    toast('Internal Server Error', {
                        className: 'custom-danger-toast'
                    });
                }
                setCropModal(false);
            }).catch((err) => {
                toast(err.response.data.message);
                setCropModal(false);
            });

        } catch (error) {
            toast("Error cropping image");
        }
    };

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="text-center" style={{ marginTop: "-60px", position: "relative" }}>
            <ToastContainer />
            <img
                src={tempPlayerProfile.profilepic || playerProfileData.profilePicture || "https://github.com/mdo.png"}
                className="rounded-circle mt-n5"
                alt="Profile"
                style={{ width: "100px", height: "100px", border: "5px solid white" }}
                onClick={() => setShowModal(true)}
            />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleIconClick}
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    padding: "5px",
                }}
                fill="currentColor"
                width="25"
                height="25"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
            >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                />
            </svg>
            <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />

            <h2 className="mt-3">{playerProfileData.name || "Captain Name"}</h2>
            <p>{playerProfileData.address || "City, India"}</p>

            {/* Cropper Modal */}
            {cropModal && (
                <div className="modal fade show" tabIndex="-1" style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Crop Profile Picture</h5>
                                <button type="button" className="btn-close" onClick={() => setCropModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <div style={{ position: "relative", width: "100%", height: "300px" }}>
                                    <Cropper
                                        image={imageSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={handleCropComplete}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={saveCroppedImage}>
                                    Save
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={() => setCropModal(false)}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
};

export default Profile;
