import axiosinstance from '../../../../axios/axiosInstance';
import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { PlayerProfileContext } from '../../../../context/PlayerProfileContext';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Pencil from '../../../Icons/Pencil';
import Insta from '../../../Icons/Insta';
import Facebook from '../../../Icons/Facebook';


const About = ({ userData }) => {
    const [profile, setProfile] = useState({});
    const [playerProfile, setPlayerProfile] = useState({});
    const [editField, setEditField] = useState(null);
    const [isEdited, setIsEdited] = useState(false);
    const { playerProfileData, setPlayerProfileData } = useContext(PlayerProfileContext);
    const location = useLocation();
    const playerId = location.state?.playerId || '';
    console.log(playerId);
    useEffect(() => {
        setProfile(prevProfile => ({
            ...prevProfile,
            ...playerProfileData
        }));
    }, [playerProfileData]);

    const handlechange = (e) => {
        setPlayerProfile({ ...playerProfile, [e.target.name]: e.target.value });
    }

    const submitprofile = () => {
        setIsEdited(false);
        const updated_value = {
            ...profile,
            ...playerProfile
        };
	console.log("this is update_Data", updated_value);
        console.log(playerProfile);
        const formData = new FormData();
        for (const key in updated_value) {
            if (key !== 'profilePicture' && key !== 'user_id') {
                formData.append(key, updated_value[key]);
            }
        }
	      formData.append('user_id', Cookies.get('user_id'));
        if (updated_value.profilePicture) {
            formData.append('profilePicture', updated_value.profilePicture);
        }

        axiosinstance.post("/user/profile_update", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: Cookies.get('uid'),
            }
        }).then((response) => {
            console.log(response);
            if (response.status == 200) {
                toast('Updated Profile', {
                    className: 'custom-success-toast'
                })
                setProfile({
                    ...profile,
                    ...playerProfile
                })
            }
            else if (response.status == 500) {
                toast('Internal Server Error', {
                    className: 'custom-danger-toast'
                });
            }
        }).catch((err) => {
            toast(err.msg);
            console.log(err);
        });
    }
    const toggleEditField = (field) => {
        setIsEdited(true);
        setEditField(editField === field ? null : field);
    };
    const beforeUnloadHandler = (event) => {
        event.preventDefault();
      
        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
      };

      if(isEdited){
        console.log("Hiii nikhil")
        window.addEventListener("beforeunload", beforeUnloadHandler)
      }else{
        console.log("Chale jaa")
        window.removeEventListener("beforeunload", beforeUnloadHandler)
      }

    return (
        <>
            <ToastContainer />
            {
                // userData dusro ki profile ke liye banaya gya hai 
                userData ?
                    <div className='container-fluid'>
                        <div className='row mt-4'>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Name</h6>
                                    <b>{userData.name || 'NULL'}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Gender</h6>
                                    <b>{userData.gender || 'NULL'}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain DOB</h6>
                                    <b>{userData.dob || 'NULL'}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Email</h6>
                                    <b>{userData.email || 'NULL'}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <div className='row'>
                                    <h6>Captain Address</h6>
                                    <b>{userData.city + ", " + userData.state}</b>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row my-3'>
                            <div className='col-md-6 mt-3 col-6'>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg></span>
                                <label className="form-label">Playing Role Nikhil</label>
                                <h6>{userData.playing_role}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Playing Position</label>
                                <h6>{userData.preferred_playing_position}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Batting Style</label>
                                <h6>{userData.batting_style || 'NULL'}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Bowling Style</label>
                                <h6>{userData.bowling_style || 'NULL'}</h6>
                            </div>
                            <h5 className='mt-3'>Physical Attributes</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Player's Height</label>
                                <h6>{userData.height || 'NULL'} cm</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Player's Weight</label>
                                <h6>{userData.weight || 'NULL'} Kg</h6>
                            </div>
                            <h5 className='mt-3'>Cricket Experience</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Year of Experience</label>
                                <h6>{userData.experience || 'NULL'} Years</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Highest Level Played</label>
                                <h6>{userData.highest_level_played || 'NULL'}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Previous Team</label>
                                <h6>{userData.previous_Team || 'NULL'}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Any Achievement</label>
                                <h6>{userData.achievement || 'NULL'}</h6>
                            </div>
                            <h5 className='mt-5'>Availability</h5>
                            <div className='col-md-6 mt-3'>
                                <label className="form-label">Days Availabble for match per week</label>
                                <h6>{userData.availability_days || 'NULL'}</h6>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className="form-label">Preferred Timing for the match</label>
                                <h6>{userData.timing || 'NULL'}</h6>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='container-fluid'>
                        <div className='row mt-4'>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Name</h6>
                                    <b>{profile.name}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Gender</h6>
                                    <b>{profile.gender}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain DOB</h6>
                                    <b>{profile.dob}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Email</h6>
                                    <b>{profile.email}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <div className='row'>
                                    <h6>Captain Phone No.</h6>
                                    <b>{profile.number}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <div className='row'>
                                    <h6>Captain Address</h6>
                                    <b>{profile.address + "," + profile.state + "," + profile.city}</b>
                                </div>
                            </div>
                        </div>
                        <hr />
                        {!profile && <h5>Please Fill the remaining details to complete your profile. </h5>}
                        <div className='row my-3'>
                            <div className="col-md-6 mt-3 col-6">
                                <label className="form-label">Playing Role</label>
                                <span className="mx-2" onClick={() => toggleEditField('playing_role')}>
                                    <Pencil />
                                </span>
                                {editField === 'playing_role' ? (
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        name="playing_role"
                                        onChange={handlechange}
                                        value={playerProfile.playing_role || ''}
                                    >
                                        <option value="">Select your playing role</option>
                                        <option value="batsman">Batsman</option>
                                        <option value="bowler">Bowler</option>
                                        <option value="all_rounder">All-Rounder</option>
                                        <option value="wicket_keeper_batsman">Wicket-Keeper Batsman</option>
                                        <option value="wicket_keeper">Wicket-Keeper</option>
                                        <option value="wicket_keeper_bowler">Wicket-Keeper Bowler</option>
                                    </select>
                                ) : (
                                    <h6>{playerProfile.playing_role || profile.playing_role || 'No role selected'}</h6>
                                )}
                            </div>

                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Playing Position</label>
                                <span className="mx-2" onClick={() => toggleEditField('playing_position')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'playing_position' ?
                                        (<select className="form-select" aria-label="Default select example" name='preferred_playing_position' onChange={handlechange} value={playerProfile.preferred_playing_position || ''}>
                                            <option value="">Select your playing position</option>
                                            <option value="Top-Order" >Top-Order</option>
                                            <option value="Middle-Order">Middle-Order</option>
                                            <option value="Lower-Order">Lower-Order</option>
                                        </select>)
                                        :
                                        (<h6>{playerProfile.preferred_playing_position || profile.preferred_playing_position || 'No position selected'}</h6>)
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Batting Style</label>
                                <span className="mx-2" onClick={() => toggleEditField('batting_style')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'batting_style' ?
                                        <select className="form-select" aria-label="Default select example" name='batting_style' onChange={handlechange} value={playerProfile.batting_style || ''}>
                                            <option value="">Select your playing role</option>
                                            <option value="righty" >Right Hand</option>
                                            <option value="lefty">Left hand</option>
                                        </select>
                                        :
                                        (<h6>{playerProfile.batting_style || profile.batting_style || 'NO Batting Style Selected'}</h6>)
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Bowling Style</label>
                                <span className="mx-2" onClick={() => toggleEditField('bowling_style')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'bowling_style' ?
                                        <select className="form-select" aria-label="Default select example" name='bowling_style' onChange={handlechange} value={playerProfile.bowling_style || ''}>
                                            <option value="">Select your playing role</option>
                                            <option value="right_hand_fast" >Right Hand Fast</option>
                                            <option value="left_hand_fast">Left hand Fast</option>
                                            <option value="right_hand_medium_pace">Right hand Medium Pace</option>
                                            <option value="left_hand_medium_pace">Left hand Medium Pace</option>
                                            <option value="left_hand_spin">Left hand spin</option>
                                            <option value="right_hand_spin">Right hand spin</option>
                                        </select>
                                        :
                                        (<h6>{playerProfile.bowling_style || profile.bowling_style || 'No Bowling Style'}</h6>)
                                }
                            </div>
                            <h5 className='mt-3'>Physical Attributes</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Your height in cm.</label>
                                <span className="mx-2" onClick={() => toggleEditField('height')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'height' ?
                                        <input type="number" className="form-control" id="playerHeight" name='height' onChange={handlechange} placeholder={profile.height} value={playerProfile.height || ''}></input>
                                        :
                                        (<h6>{playerProfile.height || profile.height || 0}</h6>)
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Your weight in kg.</label>
                                <span className="mx-2" onClick={() => toggleEditField('weight')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'weight' ?
                                        <input type="number" className="form-control" id="playerWeight" name='weight' onChange={handlechange} placeholder={profile.weight} value={playerProfile.weight || ''}></input>
                                        :
                                        (<h6>{playerProfile.weight || profile.weight || '0'}</h6>)
                                }
                            </div>
                            <h5 className='mt-3'>Cricket Experience</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Year of Experience</label>
                                <span className="mx-2" onClick={() => toggleEditField('experience')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'experience' ?
                                        <input type="number" className="form-control" id="yearofexperience" name='experience' onChange={handlechange} value={playerProfile.experience || ''}></input>
                                        :
                                        (<h6>{playerProfile.experience || profile.experience || '0'} Years</h6>)
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Highest Level Played</label>
                                <span className="mx-2" onClick={() => toggleEditField('level')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'level' ?
                                        <select className="form-select" aria-label="Default select example" name='highest_level_played' onChange={handlechange} value={playerProfile.highest_level_played || ''}>
                                            <option value="">Select your playing role</option>
                                            <option value="school/college" >School/College Level</option>
                                            <option value="district">District Level</option>
                                            <option value="state">State Level</option>
                                            <option value="national">National Level</option>
                                        </select>
                                        :
                                        (<h6>{playerProfile.highest_level_played || profile.highest_level_played || 'NA'}</h6>)
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Previous Team</label>
                                <span className="mx-2" onClick={() => toggleEditField('previous_team')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'previous_team' ?
                                        <select className="form-select" aria-label="Default select example" name='previous_Team' onChange={handlechange} value={playerProfile.previous_Team || ''}>
                                            <option value="team1" >Team 1</option>
                                            <option value="team2">Team 2</option>
                                            <option value="team3">Team 3</option>
                                        </select>
                                        :
                                        <h6>{playerProfile.previous_Team || profile.previous_Team || 'NA'}</h6>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Any Achievement</label>
                                <span className="mx-2" onClick={() => toggleEditField('achievement')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'achievement' ?
                                        <input type="text" className="form-control" id="achievement" name='achievement' onChange={handlechange} value={playerProfile.achievement || ''}></input>
                                        :
                                        <h6>{playerProfile.achievement || profile.achievement || 'NO'}</h6>
                                }
                            </div>
                            <h5 className='mt-5'>Availability</h5>
                            <div className='col-md-6 mt-3'>
                                <label className="form-label">Days Availabble for match per week</label>
                                <span className="mx-2" onClick={() => toggleEditField('available')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'available' ?
                                        <input type="text" className="form-control" id="availability" name='availability_days' onChange={handlechange} value={playerProfile.availability_days || ''}></input>
                                        :
                                        <h6>{playerProfile.availability_days || profile.availability_days || 0}</h6>
                                }
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className="form-label">Preferred Timing for the match</label>
                                <span className="mx-2" onClick={() => toggleEditField('timing')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'timing' ?
                                        <select className="form-select" aria-label="Default select example" name='timing' onChange={handlechange} value={playerProfile.timing || ''}>
                                            <option value="">Select your Preferred Timing</option>
                                            <option value="morning" >Morning</option>
                                            <option value="afternoon">Afternoon</option>
                                            <option value="evening">Evening</option>
                                        </select>
                                        :
                                        <h6>{playerProfile.timing || profile.timing || 'No timing Selected'}</h6>
                                }
                            </div>
                            <h5 className='mt-3'>Social Media Links</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">InstaGram</label>
                                <span className="mx-2" onClick={() => toggleEditField('Instagram')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'Instagram' ?
                                        <input type="text" className="form-control" id="availability" placeholder='Paste your instagram link here ' name='instagram_links' onChange={handlechange} value={playerProfile.instagram_links || ''}></input>
                                        :
                                        <h6>
                                            <Link to={profile.instagram_links}>
                                                <Insta />
                                            </Link>
                                        </h6>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Facebook</label>
                                <span className="mx-2" onClick={() => toggleEditField('Facebook')}>
                                    <Pencil />
                                </span>
                                {
                                    editField === 'Facebook' ?
                                        <input type="text" className="form-control" id="availability" placeholder='Paste your Facebook profile link here ' name='facebook_links' onChange={handlechange} value={playerProfile.facebook_links || ''}></input>
                                        :
                                        <h6>
                                            <Link to={profile.facebook_links}>
                                                <Facebook />
                                            </Link>
                                        </h6>
                                }
                            </div>
                            <button type='button' className='btn btn-dark mt-3' style={{ width: "20%" }} onClick={submitprofile}>Save</button>
                        </div>
                    </div>
            }
        </>
    )
}

export default About
