export const getCroppedImg = (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
  
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
  
        // Get Blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              // Get Base64
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve({ blob, base64: reader.result }); // Return both blob and base64
              };
              reader.readAsDataURL(blob);
            } else {
              reject(new Error("Canvas is empty"));
            }
          },
          "image/jpeg",
          1
        );
      };
      image.onerror = () => reject(new Error("Image load error"));
    });
  };
  